import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { BasicSelect, ModelSelect } from 'vue-search-select';
import FmsTowerModule from '../../../../../fms/unitInventory/fmsTower';
import FmsFloorModule from '../../../../../fms/unitInventory/fmsFloor';
import FmsProject from '../../../../../fms/unitInventory/fmsProject';
export default {
  name: 'AddLeaseUnit',
  props: [
    'parentLeaseUnitData',
    'showDetails',
    'urlSummaryData',
    'leasePrjUnitId'
  ],
  components: {
    DatePicker,
    BasicSelect,
    ModelSelect,
    FmsProject,
    FmsTowerModule,
    FmsFloorModule
  },
  watch: {
    // fmsProject: function() {
    //   this.lovFieldName = appStrings.FMSLOV.FMSPROJECT;
    //   this.lovId = this.fmsProject.value;
    //   this.getOtherLovByProjectId();
    // },
    // fmsTower: function() {
    //   this.lovFieldName = appStrings.FMSLOV.FMSTOWER;
    //   this.lovId = this.fmsTower.value;
    //   this.getOtherLovByProjectId();
    // }
  },
  data() {
    return {
      unsubscribe: null,
      showModalComponent: false,
      showFmsTowerList: false,
      showFmsFloorList: false,
      showFmsProjectListModal: false,
      showValueSetModal: false,
      editMode: false,
      loader: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      parent_value_set_id: null,
      tab: null,
      tempParentData: null,
      proj: {
        value: null,
        text: null
      },
      leaseProject: {
        value: null,
        text: null
      },
      leaseTower: {
        value: null,
        text: null
      },
      leaseFloor: {
        value: null,
        text: null
      },
      fmsProject: {
        value: null,
        text: null
      },
      fmsTower: {
        value: null,
        text: null
      },
      fmsFloor: {
        value: null,
        text: null
      },
      fmsTowersList: [],
      fmsFloorsList: [],
      unitDisplayName: null,
      unitName: null,
      unitId: 0,
      startDate: null,
      endDate: null,
      start_date_end_date: null,
      active: true,
      subUnitMapped: false,
      isShownOnPortal :false,
      approvalStatus:null
    };
  },
  mounted() {
    if (this.parentLeaseUnitData) {
      this.proj.text = this.projecName;
      this.fmsTower.text = this.towerName;
      this.fillRecordFromLeaseUnitParent(this.parentLeaseUnitData);
    } else if (this.leasePrjUnitId) {
      this.unitId = this.leasePrjUnitId;
      this.editMode = false;
    } else {
      this.editMode = true;
    }
    if (this.urlSummaryData) {
      this.unitId = this.urlSummaryData.lease_prj_unit_id;
      this.editMode = false;
    }
    this.eventBus.$on('urlData', elem => {
      this.unitId =
        elem.lease_prj_unit_id || this.urlSummaryData.lease_prj_unit_id;
      this.editMode = false;
    });
    this.getLeaseUnitDetailsById();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add' && !this.editMode) {
          this.clearLeaseUnitRecord();
        }
        if (actionName === 'save' && this.editMode) {
          this.addEditLeaseUnit();
        }
        if (actionName === 'update') {
          this.editMode = true;
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.unitId });
        }
      }
    });
  },
  methods: {
    fillRecordFromLeaseUnitParent(item) {
      this.unitId = item.lease_prj_unit_id;
      this.getLeaseUnitDetailsById();
    },
    openFmsTowerList() {
      this.showFmsTowerList = true;
    },
    selectFmsTower(towerData) {
      this.fmsTower = {
        value: towerData.fms_tower_id,
        text: towerData.fms_tower_name
      };
      this.showFmsTowerList = false;
    },
    hideFmsTowerList() {
      this.showFmsTowerList = false;
    },

    openFmsFloorList() {
      this.showFmsFloorList = true;
    },
    selectFmsFloor(floorData) {
      this.fmsFloor = {
        value: floorData.fms_floor_id,
        text: floorData.floor_name
      };
      this.showFmsFloorList = false;
    },
    hideFmsFloorList() {
      this.showFmsFloorList = false;
    },
    getLeaseUnitDetailsById() {
      const payload = {
        prjUnitId: this.unitId
      };
      this.loader = true;
      this.$store
        .dispatch('leaseUnit/getLeaseUnitDetailsById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const results = response.data.data;
            this.unitId = results.lease_prj_unit_id;
            this.leaseProject = {
              value: results.lease_prj_id,
              text: results.lease_prj_name
            };
            this.leaseTower = {
              value: results.lease_tower_id,
              text: results.lease_tower_name
            };
            this.leaseFloor = {
              value: results.lease_floor_id,
              text: results.floor_name
            };
            this.fmsProject = {
              value: results.fms_prj_id,
              text: results.fms_prj_name
            };
            this.fmsTower = {
              value: results.fms_tower_id,
              text: results.fms_tower_name
            };
            this.fmsFloor = {
              value: results.fms_floor_id,
              text: results.fms_floor_name
            };
            this.unitDisplayName = results.unit_display_name;
            this.unitName = results.unit_name;
            this.start_date_end_date = [
              new Date(results.start_date),
              new Date(results.end_date)
            ];

            this.active = results.active;
            this.subUnitMapped = results.is_sub_unit_mapped;
            this.approvalStatus = results.approval_status;
            this.isShownOnPortal = results.is_show_on_portal
            
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditLeaseUnit() {
      const payload = {
        active: this.active,
        lease_floor_id: this.leaseFloor.value,
        lease_prj_id: this.leaseProject.value,
        unit_name: this.unitName,
        lease_prj_unit_id: this.unitId,
        unit_display_name: this.unitDisplayName,
        fms_floor_id: this.fmsFloor.value,
        fms_prj_id: this.fmsProject.value,
        fms_tower_id: this.fmsTower.value,
        start_date: commonHelper.formattedDate(this.start_date_end_date[0]),
        end_date: commonHelper.formattedDate(this.start_date_end_date[1]),
        is_show_on_portal:this.isShownOnPortal
      };
      this.loader = true;
      this.$store
        .dispatch('leaseUnit/addEditLeaseUnit', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('updateProjectUnitList');
            this.editMode = false;
            this.showAlert = true;
            this.isSuccess = true;
            this.responseMsg = response.data.message;
          } else {
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        })
        .catch(() => {
          this.loader = false;
          this.showAlert = true;
          this.isSuccess = false;
          this.responseMsg = appStrings.autoFailedMsg;
        });
    },
    selectFmsProject(item) {
      this.fmsProject = {
        value: item.fms_prj_id,
        text: item.fms_prj_name
      };
      this.hideFmsProjectModalBox(false);
    },
    hideFmsProjectModalBox(flag) {
      this.showFmsProjectListModal = flag;
    },
    getOtherLovByProjectId() {
      this.loader = true;
      const payload = {
        lovFieldName: this.lovFieldName,
        lovId: this.lovId
      };
      this.$store
        .dispatch('receipt/getOtherLovByLovFieldAndId', payload)
        .then(response => {
          this.loader = false;
          if (response && response.data && response.data.data) {
            const results = response.data.data;
            if (this.lovFieldName === appStrings.FMSLOV.FMSPROJECT) {
              this.getTowerList(results.towers);
            } else if (this.lovFieldName === appStrings.FMSLOV.FMSTOWER) {
              this.getFloorList(results.floors);
            }
          }
        })
        .catch(error => {
          this.loader = false;
          alert(error.message);
        });
    },
    onSelectFmsTower(item) {
      this.fmsTower = {
        value: item.value,
        text: item.text
      };
    },
    getTowerList(towerList) {
      if (towerList && towerList.length) {
        const valueAndTextArr = towerList.map(type => {
          return {
            value: type.tower_id,
            text: type.tower_name
          };
        });
        this.fmsTowersList = valueAndTextArr;
        this.fmsTower =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.fmsTower;
      } else {
        this.fmsTowersList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    getFloorList(floorList) {
      if (floorList && floorList.length) {
        const valueAndTextArr = floorList.map(type => {
          return {
            value: type.floor_id,
            text: type.floor_name
          };
        });
        this.fmsFloorsList = valueAndTextArr;
        this.fmsFloor =
          valueAndTextArr.length === 1 ? valueAndTextArr[0] : this.fmsFloor;
      } else {
        this.fmsFloorsList = [
          {
            value: null,
            text: 'no record found'
          }
        ];
      }
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_PROJECT_LIST) {
        this.leaseProject = {
          value: item.value_set_dtl_id,
          text: item.lease_prj_name
        };
        this.leaseTower = {
          value: null,
          text: null
        };
        this.leaseFloor = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.leaseTower = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
        this.leaseFloor = {
          value: null,
          text: null
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.leaseFloor = {
          value: item.value_set_dtl_id,
          text: item.value_code
        };
      }
    },
    openValueSetModal(vsetCode) {
      if (vsetCode === appStrings.VALUESETTYPE.LEASE_TOWER_LIST) {
        this.parent_value_set_id = this.leaseProject.value;
      } else if (vsetCode === appStrings.VALUESETTYPE.LEASE_FLOOR_LIST) {
        this.parent_value_set_id = this.leaseTower.value;
      }
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearLeaseUnitRecord() {
      this.editMode = true;
      this.showAlert = false;
      this.isSuccess = false;
      this.responseMsg = '';
      this.proj = {
        value: null,
        text: null
      };
      this.leaseProject = {
        value: null,
        text: null
      };
      this.leaseTower = {
        value: null,
        text: null
      };
      this.leaseFloor = {
        value: null,
        text: null
      };
      this.fmsProject = {
        value: null,
        text: null
      };
      this.fmsTower = {
        value: null,
        text: null
      };
      this.fmsFloor = {
        value: null,
        text: null
      };
      this.fmsTowersList = [];
      this.fmsFloorsList = [];
      this.unitDisplayName = null;
      this.unitName = null;
      this.unitId = 0;
      this.startDate = null;
      this.endDate = null;
      this.start_date_end_date = null;
      this.active = true;
      this.subUnitMapped = false;
    },
    setDate(dateRange) {
      this.start_date_end_date = commonHelper.setFutureDate(dateRange);
    },

    clearVsetValues(vsetCode) {
      switch (vsetCode) {
        case this.leaseProject.value:
          this.leaseProject = {
            text: null,
            value: null
          };
          break;
        case this.leaseTower.value:
          this.leaseTower = {
            text: null,
            value: null
          };
          break;
        case this.leaseFloor.value:
          this.leaseFloor = {
            text: null,
            value: null
          };
          break;
      }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
    this.eventBus.$off('urlData');
  }
};
